// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui";

// Your web app's Firebase configuration
const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: { status: true },
      fullLabel: "Continue with email",
      buttonColor: "#c78b00",
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      disableSignUp: { status: true },
      fullLabel: "Continue with Facebook",
    },
    {
      provider: "apple.com",
      disableSignUp: { status: true },
      fullLabel: "Continue with Apple",
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: function (_authResult, _redirectUrl) {
      // No redirect
      return false;
    },
    uiShown: () => {
      const $stravaSsoButton = $("#login #strava-sso-button");
      const $authForm = $("#login #authForm");
      const $loadingSpinner = $("#login #loading-spinner");
      const $emailButton = $("[data-provider-id=password]");

      // Make the Strava SSO button appear at the same time as all the others.
      // is visible check prevents it from showing up again later during
      // facebook/apple signin process
      if ($emailButton.is(":visible")) {
        $stravaSsoButton.show();
      }

      // Any time we click the "continue with email button", hide the strava
      // button, as it otherwise floats menacingly above the rest of the
      // sign-in process
      $emailButton.on("click", () => {
        $stravaSsoButton.hide();
        const $emailCancelButton = $(".mdl-button--primary:contains(Cancel)");

        $emailCancelButton.on("click", () => {
          $stravaSsoButton.show();
        });
      });

      // Any time we click the strava login button, hide the entire form and
      // display a loading spinner until the page redirects
      $stravaSsoButton.on("click", () => {
        $authForm.hide();
        $loadingSpinner.show();
      });
    },
  },
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const startLoginUI = async (tokenInputID, authFormID, uiContainerSelector) => {
  await firebase.auth().signOut();
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const idToken = await user.getIdToken();
      document.getElementById(tokenInputID).setAttribute("value", idToken);
      document.getElementById(authFormID).submit();
    }
  });

  const ui = firebaseui.auth.AuthUI.getInstance()
    ? firebaseui.auth.AuthUI.getInstance()
    : new firebaseui.auth.AuthUI(firebase.auth());

  // ui.start waits until the DOM is loaded
  ui.start(uiContainerSelector, uiConfig);
};

const logout = async (logout_path) => {
  await firebase.auth().signOut();
  const csrfToken = document.head
    .querySelector("[name='csrf-token']")
    .getAttribute("content");
  await fetch(logout_path, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-CSRF-Token": csrfToken,
    },
    redirect: "follow",
    credentials: "include",
  });
  location.reload();
};

const signInWithCustomToken = async (token) => {
  return firebase
    .auth()
    .signInWithCustomToken(token)
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      return user.getIdToken();
    })
    .catch((error) => {
      console.error(error);
    });
};

export { startLoginUI, logout, signInWithCustomToken };
