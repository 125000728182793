setupAdmin = () => {
  $("table.table tr").on("click", e => {
    $row = $(e.currentTarget);
    $clicked = $(e.target);

    if (!$row.data("href").length > 0) {
      return;
    }

    if (!$clicked.closest("td").hasClass("action")) {
      url = $row.data("href");
      window.location = url;
    }
  });
};

$(document).ready(setupAdmin);
$(document).on("turbolinks:load", setupAdmin);
